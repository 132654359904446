.hero-wrapper {
  position: relative;
}

.hero,
.interior-hero {
  position: relative;
  background-repeat: no-repeat;
  display: block;
  z-index: 0;
  background-size: cover;
  height: 250px;
  margin-top: 0;
  background-position: top right;

  @include phablet {
    height: 350px;
  }

  @include desktop {
    height: auto;
    background-size: auto !important;
    background-position: 0% 50%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
  }

  @include max {
    background-size: cover !important;
  }

  .container {
    padding-left: 0;
    padding-right: 0;
    max-width: 100%;

    @include laptop {
      padding-left: 15px;
      padding-right: 15px;
      max-width: 960px;
    }

    @include desktop {
      max-width: 1170px;
    }

    @include wide {
      max-width: 1400px;
    }

    @include hd {
      max-width: 1560px;
    }
  }

  .cta-wrap {
    position: relative;
    padding: 100px 30px;
    z-index: 98;
    text-align: center;

    @include phablet {
      padding: 120px 30px;
    }

    @include laptop {
      padding: 140px 0;
      text-align: left;
    }

    @include desktop {
      padding: 220px 0 120px;
    }

    @include wide {
      padding: 250px 0 150px;
    }

    @include hd {
      padding: 304px 0 165px;
    }

    .btn-container {
      z-index: 99;
      position: relative;
    }

    .cta-box {
      position: relative;
      display: none;
      text-align: center;

      @include laptop {
        display: block;
        text-align: left;
      }

      .value-1 {
        letter-spacing: 2px;
        margin-left: -3px;
      }

      .value-1,
      .value-3 {
        font-family: $primary-font;
        color: $white;
        text-transform: uppercase;
        font-size: 23px;
        font-weight: 500;
        position: relative;
        z-index: 10;
        text-align: center;
        line-height: 1;
        margin-bottom: 0;

        @include laptop {
          text-align: left;
        }

        @include desktop {
          font-size: 23px;
        }
      }

      .value-2 {
        font-family: $secondary-font;
        color: white;
        text-align: center;
        font-weight: 600;
        line-height: 1.09;
        margin-top: 10px;
        margin-bottom: 15px;
        margin-left: -3px;
        text-transform: uppercase;

        @include laptop {
          text-align: left;
        }

        @include desktop {
          font-size: 59px;
        }

        @include wide {
          font-size: 69px;
        }

        @include hd {
          font-size: 79px;
        }
      }

      .value-3 {
        margin-bottom: 28px !important;
        letter-spacing: 2px;
        margin-left: -3px;
      }
    }
  }

  @include desktop {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgb(0,0,0);
      background: linear-gradient(114deg, rgba(29, 29, 29, 0.7) 53.6%, rgba(29, 29, 29, 0) 51%);
    }
  }
}

.interior-hero {
  position: relative;
  min-height: 250px;
  background-repeat: no-repeat;
  background-size: cover !important;
  background-position: right;

  @include tablet {
    max-height: auto;
    margin-bottom: 0;
  }

  .cta-wrap {
    position: relative;
    z-index: 99;
    text-align: left;
    padding: 120px 0 80px;

    @include wide {
      padding: 80px 0;
    }
  }

  .cta-box {
    position: relative;
    margin: 0 auto;
    display: none;

    @include laptop {
      display: block;
      padding-bottom: 5px;
    }
  }
}

.strapline {
  padding: 20px;
  background-color: #2a2a2a;
  position: relative;
  bottom: 0;
  width: 100%;
  z-index: 99;
  display: none;

  @include tablet {
    padding: 25px 0;
  }

  @include desktop {
    padding: 42px 0 41px;
    display: block;
    margin-top: 480px;
  }

  @include wide {
    margin-top: 550px;
  }

  @include hd {
    margin-top: 662px;
  }

  .strapline-content {
    font-size: 16px;
    color: $white;
    font-weight: 500;
    text-align: center;
    margin: 0;
    letter-spacing: -0.1px;

    @include desktop {
      line-height: 35px;
    }

    br {
      display: none;

      @include wide {
        display: block;
      }
    }

    h3 {
      color: white;
    }
  }
}

.foot-cta-box-small,
.cta-box-small {
  margin: 0 auto;
  text-align: center;
  padding: 25px 20px 25px;
  position: relative;
  background-color: #303030;

  @include mobile {
    padding: 25px 50px 25px;
  }

  @include desktop {
    display: none;
  }

  .value-1,
  .value-3 {
    font-family: $primary-font;
    color: $white;
    font-size: 18px;
    font-weight: 500;
    line-height: 1;
    position: relative;
    margin-bottom: 10px;

    @include phablet {
      font-size: 23px;
    }
  }

  .value-2 {
    font-family: $secondary-font;
    color: $white;
    font-size: 32px;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 0;
    text-transform: uppercase;
    margin-bottom: 10px;

    @include phablet {
      font-size: 40px;
    }

    br {
      display: none;

      @include phablet {
        display: inline-block;
      }
    }
  }

  .value-3 {
    color: white;
    margin-bottom: 15px;
  }
}

.mobile-hero {
  padding-left: 0;
  padding-right: 0;

  @include desktop {
    display: none;
  }

  .strapline-small {
    padding: 20px;
    background-color: $brand-primary;
    width: 100%;
    z-index: 99;

    .strapline-content {
      font-size: 16px;
      color: $white;
      text-align: center;
      margin: 0;
      letter-spacing: -0.4px;
      font-weight: 500;

      br {
        display: none;

        @include desktop {
          display: block;
        }
      }

      h3 {
        color: white;
      }
    }
  }
}
