.testimonials-panel {
  margin-bottom: 5px
  ;
  .testimonial-titles {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 0;

    @include laptop {
      margin-bottom: 46px;
    }

    h2.testimonial-title {
      font-size: 40px;
      font-weight: 600;
      color: $brand-primary;
      text-transform: uppercase;
      position: relative;
      display: inline-block;
      font-family: $secondary-font;
      margin-top: 25px;
      margin-bottom: 25px;
      margin-left: 3px;
      line-height: 1.2;

      @include tablet {
        font-size: 45px;
      }

      @include laptop {
        font-size: 50px;
      }

      @include desktop {
        font-size: 55px;
        line-height: 0.74;
      }

      span {
        color: black;
      }
    }
  }

  .testimonial-content {
    padding-left: 15px;

    @include laptop {
      padding-left: 30px;
    }
  }

  .testimonial-block {
    text-align: center;

    @include laptop {
      text-align: left;
      margin-bottom: 30px;
    }

    h3.author {
      font-family: $tertiary-font;
      font-weight: 500;
      color: $brand-primary;
      font-size: 25px;
      margin-bottom: 25px;
      text-transform: uppercase;
      color: #000;
    }

    p.testimonial {
      font-size: 16px;
      line-height: 1.5;
      text-align: center;
      position: relative;
      padding: 0;
      color: #000;
      margin-bottom: 30px;

      @include desktop {
        margin-bottom: 0;
      }

      &::before {
        content: "";
        height: 2px;
        width: 100%;
        position: absolute;
        top: -12px;
        left: 0px;
        right: 0;
        margin: auto;
        background: #393939;
        z-index: 999;
      }
    }
  }

  .quote-icon {
    border-radius: 50%;
    background-color: $white;
    height: 100px;
    width: 100px;
    border: 3px solid $black;
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
  
    @include tablet {
      margin-top: 0;
    }
  
    @include desktop {
      height: 95px;
      width: 95px;
    }
  
    @include wide {
      margin-bottom: 26px;
      margin-top: 0;
      height: 115px;
      width: 115px;
    }
  
    img {
      display: block;
      z-index: 999;
      position: relative;
      height: auto;
      width: auto;
    }
  
    &::before {
      content: '';
      background-color: $brand-primary;
      top: 5px;
      bottom: 5px;
      right: 5px;
      left: 5px;
      border-radius: 50%;
      position: absolute;
    }
  }
}

.foothero {
  position: relative;
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  margin-top: 0;
  z-index: 1;

  @include desktop {
    height: auto;
    background-position: top;
  }

  .foot-cta-wrap {
    position: relative;
    z-index: 99;
    text-align: center;
    padding: 100px 0;

    @include laptop {
      text-align: left;
    }

    @include desktop {
      padding: 120px 0;
    }

    @include wide {
      padding: 126px 0 122px;
    }

    .btn-container {
      z-index: 99;
      position: relative;
    }

    .foot-cta-box {
      position: relative;
      margin: 0 auto;
      display: none;
      text-align: center;

      @include laptop {
        display: block;
        text-align: left;
      }

      .foot-value-1 {
        letter-spacing: 2.33px;
        margin-left: -3px;
      }

      .foot-value-1,
      .foot-value-3 {
        font-family: $primary-font;
        color: $white;
        text-transform: uppercase;
        font-size: 23px;
        font-weight: 500;
        position: relative;
        z-index: 10;
        text-align: center;
        line-height: 1;
        margin-bottom: 0;

        @include laptop {
          text-align: left;
        }

        @include desktop {
          font-size: 23px;
        }
      }

      .foot-value-2 {
        font-family: $secondary-font;
        color: white;
        text-align: center;
        font-weight: 600;
        line-height: 1.09;
        margin-top: 10px;
        margin-bottom: 15px;
        margin-left: -3px;
        text-transform: uppercase;

        @include laptop {
          text-align: left;
        }

        @include desktop {
          font-size: 59px;
        }

        @include wide {
          font-size: 69px;
        }

        @include hd {
          font-size: 79px;
        }
      }

      .foot-value-3 {
        margin-bottom: 28px !important;
        letter-spacing: 2.33px;
        margin-left: -3px;
      }
    }
  }

  @include desktop {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgb(0,0,0);
      background: linear-gradient(114deg, rgba(29, 29, 29, 0.7) 52.3%, rgba(29, 29, 29, 0) 51%);
    }
  }
}

.footer-content {
  background-color: #171717;
  color: white;
  padding: 48px 0 30px;
  text-align: center;

  @include laptop {
    text-align: left;
  }

  @include hd {
    padding: 48px 0 0;
  }

  .brand {
    margin: 10px auto 40px;
    text-align: center;
    justify-content: center;
    z-index: 99;
    position: relative;

    @include desktop {
      margin: -82px auto 24px;
    }
  }

  .address-info-col {
    line-height: 1.88;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 23px;

    @include laptop {
      text-align: right;
    }

    @include desktop {
      text-align: left;
    }
  }

  .hours {
    margin-bottom: 17px;
  
    p {
      color: white;
      font-weight: 600;
      line-height: 2;
    }
  }

  .nav {
    display: inline-block;
  }

  a {
    color: white;
    font-size: 16px;
    font-weight: 600;
    margin: 10px 0 0 0;
    display: inline;

    @include laptop {
      margin: 4px 0 0 0;
    }

    &:hover {
      color: $brand-primary;
    }

    &[href^="tel:"] {
      color: inherit;
    
      &:hover {
        color: inherit;
    
        &::after {
          display: none;
        }
      }
    }
  }

  .footer-social {
    color: white;
    margin-right: 7px;
    margin-left: 7px;
    font-size: 19px !important;

    @include laptop {
      margin-right: 11px;
      margin-left: 0;
    }

    &:hover {
      color: $brand-primary;
    }
  }
}

.copyright {
  padding: 9px 0;
  font-size: 12px;
  font-weight: 400;
  background-color: #313131;
  color: $white;

  a,
  a:hover {
    color: inherit;
  }

  .copy-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-weight: 400;

    @include desktop {
      flex-direction: row;
      text-align: left;
    }

    .client-copy {
      @include desktop {
        margin-left: auto;
      }
    }

    .links-copy,
    .client-copy {
      max-width: 100%;
    }
  }
}

[data-wpr-lazyrender] {
  content-visibility: visible !important;
}