a.btn-hero {
  background-color: $white;
  color: black;
  text-transform: uppercase;
  border-radius: 0;
  border: 1px solid white;
  padding: 12.5px 25px 8px 28px;
  letter-spacing: 0.25px;
  margin-left: 0;
  font-weight: 600;
  margin-right: 0;

  @include tablet {
    margin-right: 15px;
  }

  @include wide {
    margin-left: 2px;
  }

  @include links-transition(background-color);

  &:hover,
  &:focus,
  &:active {
    background-color: darken($white, 25%);
    color: black;
    border: 1px solid white;
    
    @include links-transition(background-color);
  }
}

a.btn-hero:nth-of-type(even) {
  background-color: $brand-primary;
  border: 1px solid white;
  color: white;

  @include links-transition(background-color);

  &:hover,
  &:focus,
  &:active {
    background-color: darken($brand-primary, 10%);
    border: 1px solid white;
    color: white;
    
    @include links-transition(background-color);
  }
}

.btn-primary {
  span {
    display: none;

    @include desktop {
      display: inline-block;
    }
  }
}

.service-btn {
  border-radius: 0;
  padding: 12px 26.5px 10px 28.5px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 0.823px !important;
}