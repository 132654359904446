.why-us-panel {
  padding-bottom: 37px;
  margin-top: 7px;

  .why-titles {
    text-align: center;
    margin-bottom: 0;
    margin-top: 0;

    @include desktop {
      margin-bottom: 27px;
    }

    h2.why-title {
      font-size: 35px;
      font-weight: 600;
      color: $brand-primary;
      text-transform: uppercase;
      position: relative;
      display: inline-block;
      font-family: $secondary-font;
      margin-bottom: 32px;
      margin-left: 3px;
      line-height: 1.2;

      @include tablet {
        font-size: 45px;
      }

      @include laptop {
        font-size: 50px;
      }

      @include desktop {
        font-size: 55px;
        line-height: 0.74;
      }

      span {
        color: black;
      }
    }
  }

  .feature-row {
    flex-direction: column;

    @include tablet {
      flex-direction: row;
    }
  }

  .image-wrap {
    margin: 0 auto 24px;
    padding: 4px;
    background-color: white;
    border: 3px solid black;
    display: inline-block;
  }

  .img-col {
    text-align: center;
    margin: 0;
    order: 1;
    display: grid;

    @include desktop {
      display: block;
      order: 2;
      margin: 0 -20px;
    }
  }

  .col-custom-left {
    order: 2;

    @include desktop {
      order: 1;
    }

    .feat-text-left {
      padding-left: 12px;
    }
  }
  .col-custom-right {
    order: 3;

    .feat-text-right {
      margin-right: 0;
      padding-left: 12px;
      order: 2;

      @include desktop {
        order: 1;
        margin-right: -18px;
      }
    }

    .quote-right {
      order: 1;

      @include desktop {
        order: 2;
      }
    }

    .quote-icon  {
      left: 0;

      @include desktop {
        left: 15px;
      }
    }
  }

  .features {
    padding: 5px 10px;
    text-align: center;

    @include tablet {
      padding: 5px 15px;
    }

    @include laptop {
      padding: 0;
      text-align: left;
    }

    @include wide {
      padding: 11px;
    }

    @include hd {
      padding: 0;
    }

    .feature-title {
      font-size: 18px;
      font-weight: 500;
      line-height: 1.3;
      padding-left: 0;
      font-family: $tertiary-font;
      text-transform: uppercase;
      color: black;
      margin-bottom: 1.6rem;
      letter-spacing: 0;

      @include tablet {
        margin-bottom: 24px;
      }

      @include laptop {
        margin-bottom: 1.5rem;
        font-size: 21px;
      }

      @include desktop {
        font-size: 18px;
      }

      @include wide {
        font-size: 23px;
      }

      @include hd {
        font-size: 25px;
        letter-spacing: 0.25px;
      }
    }

    .feature-text {
      font-size: 16px;
      line-height: 1.5;
      font-weight: 400;
      padding-right: 0;
      position: relative;
      margin-bottom: 20px;

      @include hd {
        margin-bottom: 57px;
      }

      &::before {
        content: "";
        height: 2px;
        width: 100%;
        position: absolute;
        top: -13px;
        left: 0;
        right: 0;
        background: $brand-primary;
        z-index: 999;

        @include laptop {
          width: 99%;
        }
      }
    }
  }

  .about-btn {
    border-radius: 0;
    padding: 12px 26.5px 10px 28.5px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 0.823px;
  }
}

.services {
  .service-container {
    position: relative;
    padding: 12px 0;
  }

  .service-image {
    background-size: cover;
    background-position: center;
    width: 110%;
    height: 220px;
    position: relative;

    &:nth-child(1n) {
      left: -10%;
    }

    @include tablet {
      height: 300px;
    }

    @include laptop {
      position: absolute;
      height: 93%;
      width: 59.76vw;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    @include desktop {
      height: 93.5%;
    }

    @include wide {
      height: 95%;
    }

    @include hd {
      height: 95%;
      width: 55.76vw;
      min-width: 1070px;
    }
  }

  .service-row {
    .service-info-col {
      position: relative;
      max-width: 100%;
      text-align: center;
      padding: 28px 15px 18px;

      @include laptop {
        padding: 28px 0 30px;
        max-width: 435px;
        text-align: left;
      }

      @include desktop {
        padding: 50px 0 55px;
        max-width: 545px;
      }

      @include wide {
        padding: 99px 0;
      }

      @include hd {
        padding: 99px 0 113px;
      }

      &::after {
        @include laptop {
          content: '';
          width: 100%;
          top: 0;
          position: absolute;
          z-index: 0;
          height: 100.1%;
        }

        @include hd {
          height: 100%;
        }
      }

      &::before {
        @include laptop {
          content: '';
          width: 100%;
          top: 0;
          position: absolute;
          z-index: 0;
          height: 100.1%;
        }

        @include hd {
          height: 100%;
        }
      }
    }
  }

  .service-title,
  .service-text {
    position: relative;
    z-index: 1;
  }

  .service-title {
    text-transform: uppercase;
    margin-bottom: 15px;
    font-weight: 600;

    @include desktop {
      letter-spacing: -1.3px;
    }

    @include wide {
      font-size: 55px;
    }

    span {
      color: $brand-primary;
    }
  }

  .service-text {
    line-height: 1.5;
    letter-spacing: -0.45px;

    @include desktop {
      width: 513px;
    }
  }

  .btn-primary {
    z-index: 1;
    position: relative;
    padding: 13px 30px 9px;
    letter-spacing: 0.3px;

    @include laptop {
      margin-top: 17px;
    }

    span {
      display: none;

      @include tablet {
        display: inline-block;
      }
    }
  }

  .service-container:nth-child(1n),
  .service-container:nth-child(3n) {
    background-color: $brand-secondary;

    .service-image {
      @include laptop {
        left: 0;
      }
    }

    .service-row {
      justify-content: flex-end;

      .service-info-col {
        @include hd {
          right: -10px;
        }

        &::after {
          border-left: 5px solid $brand-primary;
          background-color: $brand-secondary;
          transform: skewX(347deg);
          left: -21.9%;

          @include hd {
            left: -33.9%;
          }
        }

        &::before {
          border-left: 10px solid $brand-secondary;
          transform: skewX(347deg);
          left: -23.4%;

          @include hd {
            left: -35.4%;
          }
        }
      }
    }

    .service-title,
    .service-text {
      color: #eee;
    }
  }

  .service-container:nth-child(2n),
  .service-container:nth-child(4n) {
    background-color: #fdfdfd;

    .service-image {
      @include laptop {
        right: 0;
        left: auto;
      }
    }

    .service-row {
      justify-content: flex-start;

      .service-info-col {
        @include hd {
          right: -44px;
        }

        &::after {
          border-left: none;
          border-right: 5px solid $brand-primary;
          background-color: #fdfdfd;
          left: auto;
          transform: skewX(347deg);
          right: -14%;

          @include hd {
            right: -24.1%;
          }
        }

        &::before {
          border-left: none;
          border-right: 10px solid #fdfdfd;
          left: auto;
          transform: skewX(347deg);
          right: -15%;

          @include hd {
            right: -25.6%;
          }
        }
      }
    }

    .service-title,
    .service-text {
      color: black;
    }
  }

  .service-container.odd-group {
    &:nth-child(1n) {
      .service-image {
        left: 0;
      }
    }

    &:nth-child(2n) {
      .service-image {
        left: -10%;

        @include laptop {
          left: auto;
          right: 0;
        }
      }
    }
  }

  .service-container.even-group {
    &:nth-child(1n) {
      .service-image {
        left: -10%;

        @include laptop {
          left: auto;
          right: 0;
        }
      }
    }

    &:nth-child(2n) {
      .service-image {
        left: 0;
      }
    }

    &:nth-child(1n),
    &:nth-child(3n) {
      .service-image {
        @include laptop {
          right: 0;
          left: auto;
        }
      }

      .service-row {
        justify-content: flex-start;

        .service-info-col {
          @include hd {
            right: -44px;
          }

          &::after {
            border-left: none;
            border-right: 5px solid $brand-primary;
            left: auto;
            transform: skewX(-347deg);
            right: -14%;

            @include hd {
              right: -24.1%;
            }
          }

          &::before {
            border-left: none;
            border-right: 10px solid $brand-secondary;
            left: auto;
            transform: skewX(-347deg);
            right: -15.1%;

            @include hd {
              right: -25.6%;
            }
          }
        }
      }
    }

    &:nth-child(2n),
    &:nth-child(4n) {
      .service-image {
        @include laptop {
          left: 0;
          right: auto;
        }
      }

      .service-row {
        justify-content: flex-end;

        .service-info-col {
          left: auto;

          @include hd {
            right: -10px;
          }

          &::after {
            border-right: none;
            border-left: 5px solid $brand-primary;
            right: auto;
            transform: skewX(-347deg);
            left: -20.9%;

            @include hd {
              left: -33.9%;
            }
          }

          &::before {
            border-left: none;
            border-left: 10px solid #fdfdfd;
            right: auto;
            transform: skewX(-347deg);
            left: -22%;

            @include hd {
              left: -35.4%;
            }
          }
        }
      }
    }
  }
}