.slider-nav {
  position: relative;
  display: inline-block;
  padding: 0 10px;
  margin-bottom: 5px;

  .slick-dots {
    position: static;
    width: auto;
    display: flex !important;
    align-items: center;

    li,
    button,
    button::before {
      width: auto;
      height: auto;
    }

    li {
      margin: 0 4px;

      button {
        padding: 8px 7px;
      }
    }

    button::before {
      content: '\f192';
      font-family: 'Font Awesome 5 Pro', sans-serif;
      font-size: 18px;
      position: relative;
      opacity: 1;
      color: #1d1d1d;
      font-weight: 600;

      @include phablet {
        font-size: 25px;
      }
    }

    .slick-active button::before {
      content: '\f111';
      color: $brand-primary;
      opacity: 1;
    }
  }

  .slick-arrow {
    height: auto;
    width: auto;
    padding: 1px;

    &::before {
      font-family: 'Font Awesome 6 Pro', sans-serif;
      font-weight: 700;
      font-size: 26px;
      color: #1d1d1d;
    }

    &.slick-prev::before {
      content: '\f0a8';
      opacity: 1;
    }

    &.slick-next::before {
      content: '\f0a9';
      opacity: 1;
    }
  }
}

.slick-dotted.slick-slider {
  margin-bottom: 4px;
  margin-top: 48px;
}