.sideNav {
  width: $sideNavWidth;
  #{$sideNavDirection}: -$sideNavWidth;
  box-shadow: inset 7px 0 9px -7px rgba(0, 0, 0, 0.4);
  position: fixed;
  z-index: 1000;
  height: 100%;
  top: 0;
  background: #171717;
  transition: all 0.3s ease;
  overflow-y: auto;
  padding: 10px 0;

  .menu-item-has-children > a {
    cursor: default;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &::after {
      content: ' \f0d7';
      display: contents;
      font-weight: 900;
      font-family: "Font Awesome 5 Pro", sans-serif;
    }
  }

  .menu-apply-online a {
    background-color: #009933;
  
    &:hover {
      background-color: darken(#009933, 15%);
    }
  }

  ul.nav .sub-menu {
    display: none;
    background-color: $brand-primary;
    padding: 8px 15px;
    list-style-type: none;
    position: relative;
    left: 0;

    @include links-transition(display, 0.3s, ease-in-out);

    li,
    li a:hover {
      background-color: transparent;
      color: white;
    }

    a {
      font-size: 15px;
      padding: 8px 0;
      margin-left: 10px;
    }

    &.open {
      display: block;

      @include links-transition(0.3s, ease-in-out);
    }
  }

  a,
  span {
    display: block;
    color: $white;
    font-size: 16px;
    padding: 12px 20px;
  }

  &.sideNav-open {
    #{$sideNavDirection}: 0;

    @include desktop {
      #{$sideNavDirection}: -$sideNavWidth;
    }
  }
}

/* classes applied to the body */
.sideNavBody {
  overflow-x: hidden;
  position: relative;
  #{$sideNavDirection}: 0;
  transition: all 0.3s ease;
}

.sideNavBody-push {
  #{$sideNavDirection}: $sideNavWidth;

  @include desktop {
    #{$sideNavDirection}: 0;
  }
}
