.page-header h1 {
  color: black;
}

.page-content-panel {
  img {
    display: block;
    margin: 15px auto;

    @include phablet {
      display: inline-block;
      margin: 10px 15px;
    }
  }
}

.card {
  background-color: #f4f4f4;
  padding: 15px;
  border-radius: 0;
  height: 100%;

  iframe {
    width: 100%;
    height: 400px;
    border: none;
  }
}

.quote-icon {
  border-radius: 50%;
  background-color: $white;
  height: 100px;
  width: 100px;
  border: 3px solid $black;
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;

  @include tablet {
    margin-top: 0;
  }

  @include desktop {
    height: 95px;
    width: 95px;
  }

  @include wide {
    margin-bottom: 26px;
    margin-top: 0;
    height: 115px;
    width: 115px;
  }

  img {
    display: block;
    z-index: 999;
    position: relative;
    height: 70px;
    width: 70px;

    @include wide {
      height: auto;
      width: auto;
    }
  }

  &::before {
    content: '';
    background-color: $brand-primary;
    top: 5px;
    bottom: 5px;
    right: 5px;
    left: 5px;
    border-radius: 50%;
    position: absolute;
  }
}

a.phone,
a.phone:hover {
  color: $black !important;
}

.thank-you-social {
  font-size: 40px;
  margin: 0 5px;
}

ul.page-list {
  column-count: 1;

  @include tablet {
    column-count: 2;
  }

  @include laptop {
    column-count: 3;
  }

  @include desktop {
    column-count: 4;
  }
}

h2.form-title {
  color: black;
  font-size: 16px;
  font-weight: 700;
  font-family: $primary-font;
  margin-bottom: 0;
}