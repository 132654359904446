/** Import Bootstrap functions */
@import "~bootstrap/scss/functions";
$slick-loader-path: '~slick-carousel/slick/';
$slick-font-path: '~slick-carousel/slick/fonts/';

/** Bootstrap navbar fix (https://git.io/fADqW) */
$navbar-dark-toggler-icon-bg: none;
$navbar-light-toggler-icon-bg: none;

//
//  Global Defaults
//  ---------------------------------------------

$transition-duration:               0.3s;
$transition-style:                  ease-in-out;

$section-padding: (
  base: 30px 0,
  md:   40px 0,
  lg:   50px 0,
  wd:   60px 0
);

//
//  Colors
//  ---------------------------------------------

$black:                         #000;
$white:                         #fff;

$brand-primary:                 #c80201;
$brand-secondary:               #1d1d1d;
$body-bkg:                      #fff;

// Bootstrap theme colors
$theme-colors: (
  primary:                      $brand-primary,
  secondary:                    $brand-secondary,
);

//
//  Typography
//  ---------------------------------------------

// Font Families
$primary-font:                  'Poppins', sans-serif;
$secondary-font:                'Balboa', sans-serif;
$tertiary-font:                 'Veneer', sans-serif;

$body-font:                     $primary-font;
$heading-font:                  $secondary-font;

// Line heights
$body-line-height:              1.6;

// Font Weights
$body-weight:                   400;
$h1-weight:                     700;
$h2-weight:                     400;
$h3-weight:                     700;

// Font Sizes
$body-font-sizes: (
  base: 16px
);

$h1-font-sizes: (
  base: 36px,
  md:   40px,
  wd:   48px,
);

$h2-font-sizes: (
  base: 28px,
  md:   32px,
  wd:   38px,
);

$h3-font-sizes: (
  base: 22px,
  md:   26px,
  wd:   30px,
);

// Font Colors
$body-color:                    $black;
$heading-color:                 $brand-primary;

//
//  Forms
//  ---------------------------------------------

$form-font-sizes: (
  base: 16px
);

$form-padding:    12px 10px;
$form-color:      inherit;
$form-bkg-color:  $white;
$form-border:     1px solid $black;

//
//  Mobile Navigation
//  ---------------------------------------------

// Off Canvas Navigation
$sideNavWidth:                  260px;
$sideNavDirection:              (right);

// Hamburger
$button_width:                  40px; // width of button area
$button_height:                 60px; // height of button area
$bar_thickness:                 6px; // thickness of button bars
$bar_roundness:                 0;
$button_pad:                    0; // left/right padding between button area and bars.
$button_bar_space:              8px; // spacing between button bars
$button_transistion_duration:   0.3s; // transition duration
$hamburger_normal:              $brand-primary;
$hamburger_active:              $brand-primary;

//
//  Bootstrap Variables
//  ---------------------------------------------

// Breakpoint variables (legacy)
$width_mobile:          480px;
$width_phablet:         576px;
$width_tablet:          768px;
$width_laptop:          992px;
$width_desktop:         1200px;
$width_wide:            1440px;
$width_hd:              1600px;
$width_max:             2000px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  wd: 1440px,
  hd: 1600px,
  mx: 2000px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1170px,
  wd: 1400px,
  hd: 1560px
);
