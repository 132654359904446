#skip-navigation {
  position: absolute;
  display: block;
  background: $white;
  color: $brand-primary;
  padding: 15px;
  text-align: center;
  transform: translateY(-101%);
  transition: transform 0.25s ease-in-out;
  z-index: 1000;

  &:focus {
    transform: translateY(0);
    transition: transform 0.25s ease-in-out;
  }
}

#modal {
  overflow: scroll;
  background-color: rgba($black, 0.5);

  .modal-title {
    color: $brand-primary;
    font-family: $secondary-font;
    font-size: 26px;
  }

  .modal-dialog {
    @include tablet {
      max-width: 75%;
    }

    @include hd {
      max-width: 50%;
    }
  }
}

.banner {
  border-bottom: 5px solid $brand-primary;
  background-color: white;
  position: relative;

  a.brand {
    margin-top: -16px;
    margin-left: 1px;
    margin-bottom: 16px;

    @include desktop {
      margin-top: 3px;
      margin-left: 1px;
      margin-bottom: 0;
    }

    img {
      max-width: 75%;

      @include mobile {
        max-width: 90%;
      }

      @include tablet {
        max-width: 100%;
      }
    }
  }
}

.banner .nav li {
  @extend .nav-item;
}

.banner .nav a {
  @extend .nav-link;

  text-transform: uppercase;
}

.nav-top {
  padding: 10px 3px 10px 0;
  position: relative;
  z-index: 998;
  margin-right: -23px;

  &::before {
    background-color: rgba(255, 255, 255, 0.9);
    bottom: 0;
    content: "";
    left: -25px;
    position: absolute;
    right: 0;
    top: 0;
    transform: skewX(-35deg);
    width: 100vw;
    z-index: -1;
  }

  &::after {
    bottom: 0;
    content: "";
    left: -34px;
    position: absolute;
    right: 0;
    top: 0;
    transform: skewX(-42deg);
    width: 100vw;
    z-index: -1;
  }

  li {
    padding-left: 10px;

    @include wide {
      padding-left: 17px;
    }

    @include hd {
      padding-left: 29.3px;
    }

    a  {
      color: $black;
      letter-spacing: 0;
      padding: 12.3px 10px 5.3px !important;
      font-size: 14px !important;
      font-weight: 500;

      @include wide {
        letter-spacing: 0.45px;
        font-size: 15px !important;
        padding: 12.3px 18px 5.3px !important;
      }

      &:hover {
        color: $brand-primary;
      }
    }
  }

  li.menu-item-has-children {
    position: relative;
  }

  li.menu-item-has-children > a::after {
    content: '\f0d7';
    margin-left: 9px;
    font-family: 'Font Awesome 6 Pro', sans-serif;
    font-weight: 700;
    position: relative;
  }

  li.menu-item-has-children.open .sub-menu {
    visibility: visible;
    opacity: 1;
  }

  .sub-menu {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    background-color: #171717;
    list-style: none;
    padding: 0;
    right: 0;
    box-shadow: inset 0 15px 20px -20px rgba(0, 0, 0, 0.8);
    max-width: 320px;
    min-width: 260px;
    z-index: 999;
    padding: 10px 0;

    @include links-transition((visibility, opacity));

    li {
      margin: 3px 0 !important;
      padding: 0 !important;
      color: $white;
      background-color: #171717;

      @include links-transition();

      a {
        position: relative;
        display: block;
        padding: 10px;
        color: $white;
        background-color: #171717;

        @include links-transition(background-color);

        &:hover {
          background-color: $brand-primary;
  
          @include links-transition(background-color);
        }
      }
    }
  }
}

.banner {
  z-index: 999;
  background: rgb(255,255,255);
  background: linear-gradient(122deg, rgba(255, 255, 255, 1) 35%, rgba(255, 255, 255, 0) 45%);
}

.banner .nav-primary {
  position: relative;
  z-index: 0;
  margin-right: -29px;

  &::before {
    background-color: rgba(29, 29, 29, 0.9);
    bottom: 0;
    content: "";
    left: -15px;
    position: absolute;
    right: 0;
    top: 0;
    transform: skewX(-29deg);
    width: 100vw;
    z-index: -1;

    @include wide {
      bottom: 1px;
    }
  }

  &::after {
    bottom: 0;
    content: "";
    left: -34px;
    position: absolute;
    right: 0;
    top: 0;
    transform: skewX(-42deg);
    width: 100vw;
    z-index: -1;
  }

  li {
    padding: 0 0 0 10px;

    a {
      color: white;
      letter-spacing: 0;
      padding: 21.5px 6px 12px;
      font-size: 14px !important;
      font-weight: 600;
      background-color: transparent;

      @include wide {
        padding: 21.5px 16px 12px;
        font-size: 15px !important;
        letter-spacing: 0.15px;
      }

      @include hd {
        padding: 21.5px 26.7px 11px;
      }

      @include links-transition(background-color);

      
      &:hover {
        background-color: $brand-primary;

        @include links-transition(background-color);
      }
    }
  }

  li.active a,
  li.current_page_item a {
    background-color: $brand-primary;

    @include links-transition(background-color);
  }

  li.menu-item-has-children {
    position: relative;
  }

  li.menu-item-has-children > a::after {
    content: '\f0d7';
    margin-left: 9px;
    font-family: 'Font Awesome 6 Pro', sans-serif;
    font-weight: 700;
    position: relative;
  }

  li.menu-item-has-children.open .sub-menu {
    visibility: visible;
    opacity: 1;
  }

  .sub-menu {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    background-color: #171717;
    list-style: none;
    padding: 0;
    right: 0;
    box-shadow: inset 0 15px 20px -20px rgba(0, 0, 0, 0.8);
    max-width: 320px;
    min-width: 260px;
    z-index: 999;
    padding: 10px 0;

    @include links-transition((visibility, opacity));

    li {
      margin: 3px 0 !important;
      padding: 0 !important;
      color: $white;
      background-color: #171717;

      @include links-transition();

      a {
        position: relative;
        display: block;
        padding: 10px;
      }
    }
  }
}